<template>
  <div class="thumbnail-video-list content-container-template">
    <div class="thumbnail-video-list__header">
      <T3HtmlParser
        v-if="header"
        tag="h2"
        class="thumbnail-video-list__header-title"
        :content="header"
      />

      <T3HtmlParser
        v-if="bodytext"
        class="thumbnail-video-list__header-text"
        :content="bodytext"
      />
    </div>

    <div v-if="itemList.length" class="thumbnail-video-list__content">
      <div class="thumbnail-video-list__grid" :class="gridLayoutClass">
        <ThumbnailVideoListItem
          v-for="(item, index) in itemList"
          :key="index"
          :item="item"
        />
      </div>

      <UiSwiper :options="swiperOptions" class="thumbnail-video-list__slider">
        <SwiperSlide v-for="item in itemList" :key="item.id">
          <ThumbnailVideoListItem :item="item" />
        </SwiperSlide>
      </UiSwiper>
    </div>

    <div v-if="subheader" class="thumbnail-video-list__button-wrapper">
      <ButtonBase
        :link="headerLink"
        :content="subheader"
        class="thumbnail-video-list__button"
      />
    </div>

    <div class="thumbnail-video-list__footer">
      <T3Link :to="links.b2bMc">
        <B2BMCLogo />
      </T3Link>

      <div class="thumbnail-video-list__footer-links">
        <T3Link :to="links.applePodcasts">
          <ApplePodcastsIcon />
        </T3Link>

        <T3Link :to="links.googlePodcasts">
          <GooglePodcastsIcon />
        </T3Link>

        <T3Link :to="links.spotify">
          <SpotifyIcon />
        </T3Link>

        <T3Link :to="links.youTube">
          <YouTubeIcon />
        </T3Link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Navigation } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import ApplePodcastsIcon from '~ui/assets/social-icons/apple-podcasts.svg'
import B2BMCLogo from '~ui/assets/social-icons/b2bmc.svg'
import GooglePodcastsIcon from '~ui/assets/social-icons/google-podcasts.svg'
import SpotifyIcon from '~ui/assets/social-icons/spotify.svg'
import YouTubeIcon from '~ui/assets/social-icons/youtube.svg'
import { ButtonBase, UiSwiper } from '~ui/components'

import ThumbnailVideoListItem from './ThumbnailVideoListItem.vue'

const props = defineProps<UiCeProductBoxesProps>()

const gridLayoutClass = computed(() => {
  return props.itemList.length % 2 === 0
    ? 'thumbnail-video-list__grid--even'
    : 'thumbnail-video-list__grid--odd'
})

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Navigation],
    slidesPerView: 'auto',
    spaceBetween: 16
  })
)

const links = {
  b2bMc: 'https://www.b2b-masterclass.macopedia.com/',
  applePodcasts:
    'https://podcasts.apple.com/pl/podcast/b2b-master-class/id1703580449',
  googlePodcasts:
    'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9lNTk0OTdmYy9wb2RjYXN0L3Jzcw',
  spotify: 'https://open.spotify.com/show/3dxNTREf1ycMB8ulq6Cs5N',
  youTube:
    'https://youtube.com/playlist?list=PLPCr2EIkIgImMjKrm1bAI6UY0KWiG8r8y'
}
</script>

<style lang="scss">
.thumbnail-video-list {
  overflow: hidden;

  &__header {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    margin-bottom: rem(60px);

    &-title {
      font-size: rem(40px);
      font-weight: 600;
      margin: 0;
    }

    &-text > *:last-child {
      margin-bottom: 0;
    }

    &-link {
      color: color(dodger-blue);
      text-transform: uppercase;
      font-weight: 600;
      text-align: right;
    }
  }

  &__content {
    border-radius: em(8px);
    padding: em(24px);
    background: linear-gradient(
      180deg,
      rgba(color(grey-300), 22%),
      transparent
    );
    border-bottom: 1px solid color(grey-250);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: rem(24px);

    @include media-query(max sm) {
      display: none;
    }

    & > * {
      grid-column: span 2;
    }

    &--even > * {
      grid-column: span 3;
    }
  }

  &__slider.swiper {
    overflow: visible;

    @include media-query(sm) {
      display: none;
    }

    & .swiper-slide {
      $slide-width: rem(720px);

      width: min(95%, $slide-width);
      max-width: $slide-width;
    }
  }

  &__button {
    margin-block: 0 rem(24px);

    &-wrapper {
      text-align: center;
      border-bottom: 1px solid color(grey-250);
      padding-top: rem(24px);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(8px);
    padding-top: rem(24px);

    @include media-query(max sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    & svg {
      height: 48px;
      width: fit-content;
    }

    &-links {
      display: flex;
      gap: rem(8px);

      & a {
        display: inline-block;
        border-radius: em(4px);
        border: 1px solid color(grey-300);

        & svg {
          height: 44px;

          path {
            fill: color(dodger-blue);
          }
        }
      }
    }
  }
}
</style>
