<template>
  <div class="thumbnail-video-list-tile" :class="variantColorClass(item)">
    <component
      :is="isLinkValid(item.link) ? 't3-link' : 'div'"
      :to="item.link"
      class="thumbnail-video-list-tile__thumbnail"
    >
      <UiImg :image="item.image" :sizes="{ xs: '504' }" use-image-size />
    </component>

    <div class="thumbnail-video-list-tile__text">
      <h3>
        <component
          :is="isLinkValid(item.link) ? 't3-link' : 'span'"
          :to="item.link"
        >
          {{ item.name }}
        </component>
      </h3>

      <span v-if="item.subtitle">{{ item.subtitle }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import useTheming from '~/composables/useTheming'
import type { UiCeProductBoxesItemProps } from '~/types'
import { UiImg } from '~ui/components'
import { isLinkValid } from '~ui/helpers/linkUtils'

defineProps<{ item: UiCeProductBoxesItemProps }>()

const variantColorClass = (item: UiCeProductBoxesItemProps) =>
  useTheming(item.variant)
</script>

<style lang="scss">
.thumbnail-video-list-tile {
  display: grid;
  grid-template-rows: max-content auto;
  border-radius: em(8px);
  border: 1px solid color(grey-300);
  overflow: hidden;

  &__thumbnail {
    overflow: hidden;

    & > img {
      max-width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
      transition: scale 0.3s;
    }

    &:is(:hover, :focus) > img {
      scale: 1.02;
    }
  }

  &__text {
    padding: rem(24px) rem(16px);

    & > h3 {
      display: inline-block;
      font-size: rem(24px);
      font-weight: 600;
      margin: 0;
      color: color(grey-800);

      & a {
        color: inherit;
        text-decoration: none;

        &:hover {
          color: color(blue-1000);
        }
      }
    }
  }
}
</style>
